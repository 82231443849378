*,
*:before,
*:after {
  margin: 0;

  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;

  transition: all 0.2s ease-in-out;

  @include on-event {
    text-decoration: underline;
  }
}

.ui.selection.dropdown .menu .item:first-child {
  pointer-events: none !important;
}

.btn,
a {
  transition: all 0.3s ease-in-out;

  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}

/**
 * Basic styles for input fields
 */
input[type='email'],
input[type='password'],
input[type='text'] {
  outline: none;

  appearance: none;

  @include on-event {
    box-shadow: none;
  }
}

input,
select,
button,
textarea {
  outline: none;

  @include appearance(none);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}
html {
  scroll-behavior: smooth;
}
html,
body,
#root,
.App,
.main-container {
  // width: 100%;
  // height: 100%;
}

.fullwidth-container {
  width: calc(100% - 500px);
  margin: auto;
  padding-top: 47px;

  .sidebar-content {
    right: 0;
    z-index: 99;
    position: fixed;
    top: 35%;
    transition: left 1s cubic-bezier(0.820, 0.085, 0.395, 0.895);

    .sidebar-heading {
      background: #32bea6;
      color: #fff;
      font-size: 18px;
      padding: 7px;
      font-weight: bold;
      border-top-left-radius: 10px;

      .sidebar-heading-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        cursor: pointer;

        div {
          font-size: 14px;
        }

        .sidebar-heading-percenter {
          font-size: 10px;
          padding: 3px;
          border-radius: 9px;
          background: #1ea89b;

          img {
            width: 30px;

          }
        }
      }
    }

    .sidebar-checkList-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .sidebar-checkList-content-container-active {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin: 0 10px 0 1px;

        div {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 600;
          text-decoration: line-through;
        }

        span {
          font-size: 12px;
          font-weight: 600;
          background: #32bea6;
          color: #fff;
          padding: 4px;
          border-radius: 10px;
        }
      }

      .sidebar-checkList-content-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 10px 0 1px;

        div {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 600;
        }

        span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .sidebar-checkList-disable {
      background: #e6ebf4;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      width: 30px;
      margin: 10px 0;
      margin-left: 5px;

      span {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        color: #bcc7d8;
      }
    }

    .sidebar-checkList {
      background: #32bea6;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      width: 30px;
      margin: 10px 0;
      margin-left: 5px;

      .sidebar-checkList-content-container {
        span {
          background: #32bea6;
        }
      }

      span {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .sidebar-main-container {
    position: fixed;
    right: 0;
    width: 49px;
    top: 35%;

    .sidebar-heading {
      background: #32bea6;
      color: #fff;
      font-size: 18px;
      padding: 7px;
      font-weight: bold;
      border-top-left-radius: 10px;
      cursor: pointer;

      .sidebar-percentage {
        background: #fff;
        border-radius: 50%;
        font-size: 10px;
        font-weight: bold;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #32bea6;
        cursor: pointer;
      }
    }

    .sidebar-checkList-disable {
      background: #e6ebf4;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      width: 30px;
      margin: 10px 0;
      margin-left: 5px;

      span {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #bcc7d8;
      }
    }

    .sidebar-checkList {
      background: #32bea6;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      width: 30px;
      margin: 10px 0;
      margin-left: 5px;

      span {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}

.main-container {
  // display: flex;
}

.notif-active {
  margin-top: 53px;

  .nav-container {
    height: calc(100% - 53px);
  }

  .title-container {
    z-index: 2;
  }
}

// body.dimmed.dimmable {
//   overflow-y: scroll !important;
// }

.inner-main-contaner {
  display: flex;
  height: 95%;
}

.overflow-hide {
  // position: fixed;
  // overflow: hidden;
}

@media screen and (max-width: 1500px) {
  .fullwidth-container {
    width: calc(100% - 350px) !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .notif-active {
    margin-top: 51px;

    .nav-container {
      height: calc(100% - 51px);
    }
  }

  .fullwidth-container {
    width: calc(100% - 0px);
  }
}

@include respond-to(lg-down) {
  .fullwidth-container {
    width: calc(100% - 0px);
    padding-left: 50px;
  }
}

@include respond-to(sm-down) {
  .fullwidth-container {
    padding-left: 0;
    margin-left: 50px;
  }
}

@include respond-to(xs) {
  .fullwidth-container {
    width: calc(100% - 50px) !important;
  }

  .title-container {
    z-index: 2;

    .title-wrapper {
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .fullwidth-container {
    .sidebar-main-container {
      right: 0;
      z-index: 9;
      width: 49px;
    }
  }
}