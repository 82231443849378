.flag-dropdown{
    background-color: #fff !important; 

    margin: 2px !important;
    border: none !important;

    .selected-flag{
        background-color: #f4f4f4 !important; 
        border: none;
    }

    .country-list{
        width: 190px !important;
        color: black !important;
    }
}

.info-value > div{
    background-color: #171b26 !important;
    color: rgb(255, 255, 255); 
    border: 1px solid rgb(164, 171, 197); 
    font-size: 12px; 
    border-radius: 5px; 
    margin-top: -4px; height: 30px;
}

.react-tel-input > input{
    background-color: #fff !important;
    color:black;
    border: 1px solid rgb(164, 171, 197); 
    font-size: 12px; 
    border-radius: 5px; 
    margin-top: -4px; height: 30px;
}
.notification_detail{
    .ui.table{
        thead{
            th{
                font-weight: 400;
            }
        }
    }
}
.call-info-container-main{
        .chip{
        color: white;
        border: 1px solid white;
        padding: 4px 6px;
        display: inline-block;
        font-size: 12px;
        border-radius: 15px;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.account-info-container{
    padding: 12px !important;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    float: initial !important;
    div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p{
            color: white;
            margin-bottom: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}