@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
$grey:#12C197;
$lightGrey:#fff;


$green:#009FAD;
$black:#fff;
$hblack:#212121;

$padHeight:80px;

$mainFont: 'Montserrat', sans-serif;



.green{
  color:$green;
}

ul,
li{
  -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}

.dialpad{

  box-shadow: 0 0 10px 8px #009FAD;  
 // height:590px;  
 //padding-bottom: 40px;
  position:fixed;
  z-index: 99999;
  margin: auto;
  max-width: 360px;
  position: absolute;
  left: 1300px;
   top: 100px;
   input {
    -webkit-box-shadow: 0 0 5px 2px #1d1e22;
    -moz-box-shadow: 0 0 5px 2px #1d1e22;
    box-shadow: 0 0 5px 2px #1d1e22;
}

  
  left:calc(50% - 350px);
  .list{
    background:rgba(0,0,0,0.3);       
    margin-bottom: 30px;
    padding-bottom:30px;
    //margin-top:2%;
    float:left;
    box-sizing:border-box;
    padding:15px;
    ul{
      list-style:none;
    }
    .card{
      min-height:70px;
      width:100%;
      background:white;
      margin-bottom:15px;
      border-radius:1px;
      overflow:hidden;
      img{
        float:left;
        width:auto;
        max-height:70px;
      }
      .text{
        float:right;
        width:67%;

        padding-top:3px;
        color:$black;
        font-family: 'Montserrat', sans-serif;
        font-size:.9rem;
        .number{          
        
        
        -webkit-margin-before: 0em;
          -webkit-margin-after: 0em;
          -webkit-margin-start: 0px;
          -webkit-margin-end: 0px;
          -webkit-padding-start: 0px;
          margin-top:-13px;
          color:$grey;
          font-size:.8rem;
        }
      }
     
    }
  }
  .main{
    float:right;
    height:100%;
    min-width: 360px;
    width:100%;
    background-color:#1d1e22;

    #connecting{
      font-size: 16px !important;
      position:relative;
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;     
      color:$green;
      border:1px solid transparent;
      width:100%;    
      min-height: 93px;
       padding: 30px 20px;
      box-sizing:border-box;
      font-family:$mainFont;
      overflow:auto;
      outline:0;
      &::selection{
        color:$green;
      }
      &:before,
      &:after{       
        position:absolute;
        font-size:1.6rem;
        top:40px;
        color:$grey;
      }
      &:before{
        left:30px;
      }
      &:after{
        right:30px;
        transform:rotate(45deg);
      }
      &:first-letter{
        // color:transparent;
      }
    }

    #error{
      font-size: 15px !important;
      position:relative;
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;     
      color:$grey;
      border:1px solid transparent;
      width:100%;    
      min-height: 93px;
       padding: 30px 20px;
      box-sizing:border-box;
      font-family:$mainFont;
      overflow:auto;
      outline:0;
      &::selection{
        color:$grey;
      }
      &:before,
      &:after{       
        position:absolute;
        font-size:1.6rem;
        top:40px;
        color:$grey;
      }
      &:before{
        left:30px;
      }
      &:after{
        right:30px;
        transform:rotate(45deg);
      }
      &:first-letter{
        // color:transparent;
      }
    }
    
    #number{
      font-size: 24px !important;
      position:relative;
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;     
      color:$green;
      border:1px solid transparent;
      width:100%;    
      min-height: 93px;
       padding: 30px 20px;
      box-sizing:border-box;
      font-family:$mainFont;
      overflow:auto;
      outline:0;
      &::selection{
        color:$green;
      }
      &:before,
      &:after{       
        position:absolute;
        font-size:1.6rem;
        top:40px;
        color:$grey;
      }
      &:before{
        left:30px;
      }
      &:after{
        right:30px;
        transform:rotate(45deg);
      }
      &:first-letter{
        // color:transparent;
      }
    }
    ul{
      list-style:none;
      margin:0 auto;
      padding-left:2%;
      li{
        display:inline-block;
        width:32%;
        font-family:$mainFont;
        margin-bottom:7px;
        &:last-child{
          margin-top:10px;
          width:100%;
        }
        .dialphoneIcon{
          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
        .pad,
        .dial{
          margin:0 auto;
          position:relative;
          height:$padHeight;
          width:$padHeight;
          color:$black;
          padding:calc($padHeight/5);
          font-size:calc($padHeight/3);
          text-align:center;
          box-sizing:border-box;
          border-radius:calc($padHeight/2);         
         
          .connect-call{
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
            color: #fff;
          }    
          
          

          .disconnect-call{
            color: #fff !important;
          }
          
          .disabled-connect-call{
            color: #fff !important;   
          }

          .pulse {
            height: 80px;
            color: #fff !important;
            width: 80px;
            background-color: #2fd38e;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative
        }

        .disabled-connect-call:hover{
          color: #f7f7f7;            
        }

        .pulse-animated {
          height: 80px !important;
          color: #fff !important;
          width: 80px !important;
          background-color: #2fd38e;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative
      }

      .pulse-animated i {
        -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
            color: #fff;
    }



        .pulse-animated::before { 
          content: "";        
          position: absolute;         
          border: 1px solid #2fd38e;
          width: calc(100% + 40px);
          height: calc(100% + 40px);
          border-radius: 50%;
          animation: pulse 1s linear infinite
      }
      
      .pulse-animated::after {  
         content: "";       
          position: absolute;
          border: 1px solid #2fd38e;
          width: calc(100% + 40px);
          height: calc(100% + 40px);
          border-radius: 50%;
          animation: pulse 1s linear infinite;
          animation-delay: 0.3s
      }       


        cursor:pointer;
          .sub{
            position:absolute;
            color:$grey;
            font-size:.8rem;
            text-transform:uppercase;
            bottom:calc($padHeight/5);
            width:100%;
            left:0;
            text-align:center;
          }
          &:hover{
            background:#009FAD;
          }
        }
        .dial{
          color:$green;
          font-size:4rem;
          &:hover{
            background:transparent;
          }
        }
      }
    }
  }
}

.PhoneInputInternationalIconPhone{
  display: none;
}


.PhoneInput input{
  display: none;
}


@keyframes pulse {
  0% {
      transform: scale(0.5);
      opacity: 0
  }

  50% {
      transform: scale(1);
      opacity: 1
  }

  100% {
      transform: scale(1.3);
      opacity: 0
  }
}

.no-pad{
  margin:0 auto;
  position:relative;
  height:$padHeight;
  width:$padHeight;
  color:$black;
  padding:calc($padHeight/5);
  font-size:calc($padHeight/3);
  text-align:center;
  box-sizing:border-box;
  border-radius:calc($padHeight/2); 
}
.flag-dropdown .country-list li{
  width: auto !important;
  display: flex !important;
  flex-direction: row !important;
}

#out-dialer .react-tel-input .form-control, #out-dialer  .react-tel-input .flag-dropdown.open .selected-flag, #out-dialer  div.flag-dropdown div.selected-flag{
  background-color: #1d1e22 !important;
  color: #009FAD !important;
  font-size: 1.5rem; 
}

#out-dialer  .react-tel-input .form-control{ 
  border: 2px solid #009fad !important;
}


.close-btn{
   position: absolute;
    cursor: pointer;
    display: block;
    left: 88% !important;   
    top: 6px;
    color: #E32227;
    font-weight: bolder;
    font-size: 1.3rem;
    width: 20px;
    background-color: #1d1e22;
}
.wia-call {
  border: 2px solid #009fad !important;
  background-color: #1d1e22 !important;
  color: #009FAD !important;
  .text {
    color: #009FAD !important;
    font-size: 17px !important;
    font-weight: 500 !important;
  }
}
.dialpad .main ul li .dial .disconnect-call {
  background-color: red;
}
.dialpad .main ul li .dial .pulse-animated::before,
.dialpad .main ul li .dial .pulse-animated::after {
  border-color: red;
}



