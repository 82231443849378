.card-view-container {
  width: calc(100% - $nav-width);
  margin-left: $nav-width;
  margin-top: 60px;
  padding: 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  background-color: #f6f7f8;
  min-height: calc(100vh - 60px);
  
  .board {
    display: flex;
    grid-gap: 16px;
    overflow: auto;
    min-height: calc(100vh - 100px);
margin-top: 50px;
    .column {
      background-color: #ffffff;
      padding: 0;
      border-radius: 5px;
      width: 20%;
      flex: 0 0 20%;
      
      &.add-stage-column{
        background-color: #fff;
        padding: 10px;
        color: #007bff;
        input{
          border: 1 px solid grey;
          background-color: #fff;
          padding: 5px 10px;
        }
      }
      .avtar-root{
        height: 20px;
        width: 20px;
        font-size: 10px;
      }
      .column-header {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 2px solid #f6f7f8;
        
        p{
          margin: 0;
        }
      }
      
      .column-cards {
        padding: 10px;
        
        .card {
          background-color: #f6f7f8;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          cursor: pointer;
          
          .card-title{
            display: flex;
            
            p{
              margin: 0;
            }
            
            img{
              // margin-left: auto;
            }
          }
          
          .card-dropdown{
            background: #fff;
            border-radius: 50px;
            width: max-content;
            padding: 5px 20px;
          } 
                
          &:last-child{
            margin-bottom: 0;
          }
                     &:hover {
                       background: #e0e0e0;
                     }
        }
      }
    }
  }


  input {
    width: 100%;
    padding: 4px;
    border: none;
    border-radius: 5px;
    background-color: #f6f7f8;
  }  

.ui.checkbox label {
  padding-left: 25px;
}




}
.form-wrapper{
  border: 1px solid rgb(226, 226, 226);
  border-radius: 8px;
  .form-heading{
    font-size: 24px;
    font-weight: 600;
  }
}
.stage-popup{
  .MuiPaper-root{
    box-shadow: none;
    .MuiList-root{
      border: 1px solid #e2e5e7;
      border-radius: 5px;
      box-shadow: none;
      min-width: 130px;
      
      .MuiMenuItem-root{
        justify-content: space-between;
      }    
    }
  }
}

.top-section{
      display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px;
        padding-top: 0;
}
.ui.bottom.center.popup{
  z-index: 500001;
}

.white-card{
  background-color: #fff;
  .ui.grid>.row {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    
        .form-heading{
          font-size: 20px;
        }
}
.pipeline-dropdown{
  margin-left: 15px;
    border: 1px solid grey;
    padding: 6px 42px;
    
}