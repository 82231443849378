.quicksetup {
  // display: flex;
  // align-items: center;
  padding: 35px 25px 25px;
  width: 100vw;
  min-height: 100vh;

  &-step {
    background-color: #f5f7fa;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 961px) {
      padding: 30px 0;
    }
  }

  &-step-wrap {
    max-width: 1080px;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
    @media screen and (min-width: 961px) {
      display: flex;
      justify-content: center;
    }
  }

  &-header {
    padding: 10px 20px;

    font-size: 20px;

    border-bottom: 1px solid $gray100;
  }

  &-wrapper {
    display: flex;
    margin: 0 auto;
    width: 1515px;
    max-width: 100%;
  }

  &-wizard {
    width: 100%;
    @media screen and (min-width: 961px) {
      max-width: 325px;
    }
  }

  &-content {
    width: 69.8%;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 36px;

    .ui.button {
      border-radius: 12px;
      &.btn-blue {
        padding: 0;
        width: 180px;
        height: 50px;
        font-size: 16px;
        border-bottom: 1px solid $gray100;
      }
    }
  }

  .lead-information-container {
    background: rgb(255, 255, 255);
    padding: 28px 45px 21px;

    .lead-information-heading {
      text-align: center;
      margin-bottom: 0;
      font-family: 'Gibson Regular';
      font-size: 24px;
      font-weight: 600;
    }

    .lead-information-sub-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      padding: 30px;

      .lead-information-sub-main-wrapper {
        width: 100%;
      }

      .lead-information-sub {
        margin: 10px 0;
        font-family: 'Gibson Regular';
        font-size: 20px;
        font-weight: 300;
      }

      .lead-information-sub-main {
        padding: 24px 32px 24px;
        max-width: 600px;
        margin: auto;

        .ui.selection.dropdown {
          background: $gray100;
          border-color: $gray100;
        }
      }
    }
  }
}

.quicksetupInner {
  display: flex;
}

@media (max-width: 1078px) {
  .quicksetupInner {
    display: block;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .quicksetup {
    &-wrapper {
      zoom: 80%;
    }
  }

  .call-routing .code-holder .resend-code:after {
    border-width: 1.5px;
  }
}

@media (max-width: 775px) {
  .quicksetup {
    p,
    h2,
    button,
    h3,
    span {
      font-size: 14px !important;
    }

    padding: 20px 0 0 0;

    &-wrapper {
      max-width: 100%;
      flex-direction: column;

      .quicksetup-wizard {
        margin-right: 0;
        width: 100%;
      }

      .quicksetup-content {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 320px) {
  .quicksetup-content {
    width: 100% !important;
  }
  .input-wrapper {
    .ui.input {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) and (min-width: 321px) {
  .quicksetup-content {
    width: 80% !important;
  }
  .input-wrapper {
    .ui.input {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1080px) and (min-width: 640px) {
  .quicksetup-content {
    width: 75% !important;
  }
}

@include respond-to(md) {
  .quicksetup {
    p,
    h2,
    button,
    h3,
    span {
      font-size: 14px !important;
    }

    &-wrapper {
      max-width: 100%;
      flex-direction: column;

      .quicksetup-wizard {
        margin-right: 0;
        width: 100%;
      }

      .quicksetup-content {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.quicksetup-content {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  // background: #fff;

  .border {
    padding: 28px 40px 0px 40px;
    border: none !important;
    font-family: 'Gibson Regular';

    h2 {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
    }

    label {
      color: #000;
      font-weight: 500;
      font-size: 17px;
      margin-top: 20px;
    }
  }

  .btnContinue {
    text-align: right;
    margin-right: 20px;

    button {
      background: #0071eb;
      color: #fff;
      margin-right: 15px;
      margin-bottom: 10px;

      &:hover {
        background: #1c5fa7;
        color: #fff;
      }
    }
  }

  .call-routing {
    .react-tel-input {
      input {
        padding: 10px 10px 10px 50px;
      }
    }
  }
}

@include respond-to(md) {
  .quicksetup {
    p,
    h2,
    button,
    h3,
    span {
      font-size: 14px !important;
    }

    &-wrapper {
      max-width: 100%;
      flex-direction: column;

      .quicksetup-wizard {
        margin-right: 0;
        width: 100%;
      }

      .quicksetup-content {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 320px) {
  .quicksetup-content {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) and (min-width: 321px) {
  .quicksetup-content {
    width: 80% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 640px) {
  .quicksetup-content {
    width: 75% !important;
  }
}

@include respond-to(sm) {
  .quicksetup {
    p,
    h2,
    button,
    h3,
    span {
      font-size: 14px !important;
    }

    &-wrapper {
      max-width: 100%;
      flex-direction: column;

      .quicksetup-wizard {
        margin-right: 0;
        width: 100%;
      }

      .quicksetup-content {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

// .wizard-item:before {
//   border: 2px solid #0071eb;
//   background-color: #0071eb;
// }

.wizard-item {
  color: #000;
}

.btnFooter {
  text-align: right;
  padding-bottom: 10px;
  margin-right: 20px;

  .btnNext {
    background: #0071eb;
    color: #fff;
    margin-right: 10px;

    &:hover {
      background: #1c5fa7;
      color: #fff;
    }
  }
}

div.call-routing-content .form-control {
  background-color: white !important;
}

div.call-routing-content .selected-flag {
  background-color: white !important;
}
.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}
.welcome-box {
  border-radius: 10px;
  .box-heading {
    padding: 30px 3rem 0px;
    background-color: #e3eefd;
    h3 {
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    p {
      color: #8694b6;
      max-width: 350px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    img {
      height: 120px;
      width: 185px;
      object-fit: contain;
    }
  }
  .box-content {
    padding: 30px 3rem 0px;
    h4 {
      font-size: 15px;
      margin-bottom: 20px;
      font-weight: 700;
    }
    p {
      color: #8694b6;
      font-size: 15px;
    }
    .ui.selection.dropdown {
      box-shadow: none;
    }
    .announce-text {
      font-size: 12px;
    }
    ul {
      @media (max-width: 599px) {
        flex-wrap: wrap;
      }
      li {
        width: 100%;
        border: 1px solid rgb(220, 220, 220);
        margin-right: -1px;
        list-style: none;
        text-align: center;
        padding: 10px 0;
        p {
          font-size: 12px;
        }
        label {
          margin: 0;
        }
      }
    }
  }
}
.availability-box {
  .box-content {
    .ui.selection.dropdown {
      box-shadow: none;
      border: none;
      font-size: 14px;
      width: fit-content;
    }
  }
}

.bg-grey-color {
  input {
    background-color: #e8e8e8 !important;
  }
}
.time-zone-dropdown {
  ul {
    background-color: #fff;
    li {
      padding: 0 !important;
      button {
        padding: 15px 10px;
      }
    }
  }
}

.testcall-container {
  padding: 30px 3rem;
  .testcall-heading {
    h6 {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    h5 {
      font-size: 26px;
      color: #2d1f8c;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .hours-box {
      border-radius: 10px;
      padding: 15px 10px;
      background-color: #f6f6f6;
      h6 {
        margin: 0;
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 25px;
    }
  }
  .guide-box {
    border-radius: 10px;
    padding: 15px 10px;
    background-color: #f6f6f6;
    i {
      font-size: 21px;
      margin-right: 10px;
      color: #3bd1bb;
    }
    p {
      line-height: 1.5em;
      font-size: 16px;
      span {
        font-weight: 600;
        color: #3bd1bb;
      }
    }
  }
  .testcall-number {
    h6 {
      font-size: 22px;
      color: #3587c8;
      font-weight: 600;
      margin-bottom: 10px;
    }
    span {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .important-box {
    border-radius: 10px;
    padding: 15px 10px;
    background-color: #f6f6f6;
    svg {
      margin-right: 10px;
      color: #ed3f58;
      height: 25px;
      width: 25px;
    }
    ul {
      padding-left: 20px;
      li {
        line-height: 1.5em;
        font-size: 16px;
        span {
          font-weight: 600;
          color: #707088;
        }
      }
    }
    .important-text {
      font-size: 18px;
      font-weight: 600;
      color: #ed3f58;
    }
  }
}
.footer-button {
  padding: 16px 3rem;
  flex-wrap: wrap;
  button {
    margin: 0 0 15px 0 !important;
    width: 100% !important;
    font-weight: bold;
    border: 2px solid #0071eb !important;
    height: 50px;
    &.btn-back {
      color: #0071eb !important;
      background-color: #fff;
    }
    &:last-child {
      margin: 0 !important;
    }
  }
}
.tab-main {
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow-x: auto;
  }
  .MuiTabs-indicator {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

@media screen and (max-width: 767px){
  .quicksetup-step-wrap{
    min-height: 100vh;
    height: auto;
    background: #fff;
    padding: 50px 10px;
  }
}
