.golive {
  background-color: $white;

  img {
    margin-bottom: 3px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 139px 0;
  }

  &-title {
    margin-bottom: 22px;

    font-size: 30px;
    font-weight: 600;
  }

  &-subtitle {
    margin-bottom: 26px;

    font-size: 20px;
  }

  &-desc {
    margin-bottom: 33px;

    font-size: 15px;
    color: $gray300;
  }

  &-btn-holder {
    margin-bottom: 48px;

    button {
      &:first-of-type {
        margin-right: 10px;
        width: 308px;
      }

      &:last-of-type {
        width: 176px;
      }
    }
  }

  .ui.button.btn-blue,
  .ui.button.btn-alt-blue {
    padding: 0;
    height: 50px;

    text-transform: uppercase;

    border-radius: 5px;
  }

  &-anchor {
    font-size: 15px;
  }
}

@media (max-width: 775px) {
  .golive {
    .golive-content {
      padding: 50px 15px;
      .golive-btn-holder {
        button {
          width: 100% !important;
          margin: 7px 0px !important;
        }
        button:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }
}
.use-cases {
  margin: 0;
}
.use-cases li {
  list-style: none;
  margin-bottom: 30px;
  padding: 30px 20px;
  font-size: 18px;
  cursor: pointer;
  &:hover{
    background-color: #fdf0ff;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.call-back {
    border: 1px solid #48b17a;
    color: #48b17a;
  }
  &.call-tracking {
    border: 1px solid #46a7f5;
    color: #46a7f5;
  }
  &.virtual-phone {
    border: 1px solid #e45b87;
    color: #e45b87;
  }
  svg {
    float: right;
  }
}
.virtual-cc{
  padding: 40px 0;
  .payment-info {
    background-color: #fff;
    box-shadow: 0 0 0 1px #d8e2eb;
    padding: 50px 20px;
    h2 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .wcsh-row {
      display: inline-flex;
      align-items: center;
      h2 {
        font-size: 20px;
        margin-bottom: 3px;
        margin-right: 10px;
      }
      .cc-logo {
        box-sizing: border-box;
        height: 24px;
        display: inline-block;
        align-items: center;
        margin-right: 10px;
        text-align: center;
        box-shadow: 0 0 0 1px #dce2ee;
        vertical-align: top;
        img {
          height: 100%;
        }
      }
      .why-text {
        color: #5373bd;
        font-weight: 600;
      }
    }
    .ui {
      &.checkbox {
        label {
          padding-left: 22px;
        }
      }
      &.button {
        padding: 20px;
        text-transform: capitalize;
      }
    }
    .sort-notes {
      text-align: center;
      color: #4e5879;

      span {
        margin: 6px 2px;
        background-color: #cef2df;
        padding: 9px;
        border-radius: 20px;
        font-size: 12px;
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
.already-acc {
  margin-top: 20px;
  text-align: center;
}
.card-setting {
  background-color: #fff;
  // box-shadow: 0 0 0 1px #d8e2eb;
  padding: 50px 0 0;
  position: relative;
  min-height: 570px;
  .card-tail {
    position: absolute;
    right: -10px;
    top: 20px;
    background-color: #6c8bdd;
    color: #fff;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-right: 10px solid transparent;
      border-top: 10px solid;
      border-top-color: #395ab1;
      right: 0;
      top: 100%;
    }
  }
  h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .plan-detail {
    border: 1px solid #e2e5ec;
    background: #fafdff;
    padding: 20px;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    h6 {
      font-weight: 600;
      margin-bottom: 15px;
      span {
        float: right;
      }
    }
    p {
      margin: 0;
      span {
        font-weight: 600;
      }
    }
  }
}
.assigned-screen {
  background-color: #fff;
  padding: 20px 3rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: bold;
    margin-bottom: 30px;
  }
  .assigned-inner {
    margin: auto;
    width: 100%;
    .number {
      font-size: 25px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      max-width: 300px;
      margin: 0 auto 30px;
      img {
        border-radius: 5px;
        margin-right: 5px;
      }
    }
    .number-onbording {
      input {
        padding-left: 45px;
        border: none;
        font-size: 24px;
      }
      .selected-flag {
        .flag {
          transform: scale(1.5);
          .arrow {
            display: none;
          }
        }
      }
    }
    h6 {
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      color: #999;
      margin-bottom: 30px;
    }
  }
}
.welcome-go-live {
  display: flex;
  margin-bottom: 10px;
  margin-left: 35%;
  img {
    width: calc(50% - 40px);
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    margin: 0 20px;
  }
}
