.wizard {
  padding: 28px 45px 21px;
  height: 100%;
  background-color: #cee3ff;

  &-title {
    margin-bottom: 30px;

    font-family: $gibson;
    font-size: 24px;
    font-weight: 600;
    @media screen and (max-width: 960px) {
      text-align: center;
    }
  }

  &-holder {
    display: flex;
    margin-bottom: 10px;
    counter-reset: number;
    flex-direction: column;
    @media screen and (max-width: 960px) {
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }
  }

  &-item {
    position: relative;

    padding: 7px 0 7px 50px;

    font-size: 16px;
    color: $link-color;
    @media screen and (max-width: 960px) {
      height: 40px;
      padding: 7px 0 7px 70px;
      font-size: 0;
      &:last-child {
        padding: 7px 0 7px 30px;
      }
    }
    @media screen and (max-width: 575px) {
      padding: 7px 0 7px 50px;
      height: 30px;
    }
    @media screen and (max-width: 399px) {
      padding: 7px 0 7px 40px;
    }

    &.active {
      color: $black;

      &:before {
        border: 2px solid $link-color;
        background-color: $link-color;
      }
    }

    &:before {
      counter-increment: number;
      content: counter(number);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 39px;
      height: 39px;
      border: 2px solid $link-color;
      border-radius: 100%;
      padding-top: 3px;
      font-size: 16px;
      @media screen and (max-width: 575px) {
        width: 30px;
        height: 30px;
      }
    }

    &:not(:last-of-type) {
      @media screen and (min-width: 961px) {
        margin-bottom: 38px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 20px;
        @media screen and (min-width: 961px) {
          width: 1px;
          height: 100%;
          border-right: 1px dashed $link-color;
        }
        @media screen and (max-width: 960px) {
          height: 1px;
          top: 50%;
          right: 0;
          border-top: 1px dashed $link-color;
          right: 0;
          left: 50%;
          margin-left: 2px;
          @media screen and (max-width: 399){
            left: 67%;
          }
        }
      }
    }
  }
  &-step-title {
    display: none;
    @media screen and (max-width: 960px) {
      display: block;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
  }

  &-bottom-holder {
    position: relative;

    .title {
      font-family: $gibson;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    .description-text {
      margin-bottom: 10px;
      width: 215px;
      max-width: 100%;

      font-size: 17.7px;
      line-height: 28px;
    }

    .ui.button.btn-blue {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 145px;
      height: 50px;

      font-size: 14px;

      border-radius: 4px;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
